import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import logger from "redux-logger";
import dashboardReducer from "../pages/dashboard/redux/slice";
import courseDetailsReducer from "../pages/course-details/redux/slice";
import skillsReducer from "../pages/products/skills/redux/slice";
import learningReducer from "../pages/learning/redux/slice";

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    courseDetails: courseDetailsReducer,
    skills: skillsReducer,
    learning: learningReducer,
  },
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV !== "production"
      ? getDefaultMiddleware().concat(logger)
      : getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
