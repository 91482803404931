import { IonLabel, IonTabBar, IonTabButton } from "@ionic/react";

import "./CourseTabs.component.scss";
import { CourseObjectType } from "../../../types/course";
import { slugifyName } from "../../shared/course/utils";
import React from "react";
import { RouteComponentProps } from "react-router";

type CourseTabsComponentPropTypes = {
  course: CourseObjectType;
};

const CourseTabsComponent = (props: CourseTabsComponentPropTypes) => {
  const { course } = props;
  const courseSlug = slugifyName(course?.name);

  const getSelectedTab = () => {
    const url = window.location.pathname;
    return url?.replace(`/courses/${courseSlug}/${course.course_id}/`, "");
  };
  return (
    <div className="container">
      <IonTabBar
        selectedTab={getSelectedTab()}
        className="course-tabs"
        color="dark"
      >
        <IonTabButton
          tab="table-of-contents"
          href={`/courses/${courseSlug}/${course.course_id}/table-of-contents`}
        >
          <IonLabel className="course-tab-label">Table of contents</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="transcripts"
          href={`/courses/${courseSlug}/${course.course_id}/transcripts`}
        >
          <IonLabel className="course-tab-label">Transcripts</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="description"
          href={`/courses/${courseSlug}/${course.course_id}/overview`}
        >
          <IonLabel className="course-tab-label">Overview</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="related-courses"
          href={`/courses/${courseSlug}/${course.course_id}/related-courses`}
        >
          <IonLabel className="course-tab-label">Related Courses</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="resources"
          href={`/courses/${courseSlug}/${course.course_id}/resources`}
        >
          <IonLabel className="course-tab-label">Course Resources</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </div>
  );
};

export default CourseTabsComponent;
