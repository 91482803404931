import React, { useEffect, useRef } from "react";
import {
  IonButton,
  IonCard,
  IonContent,
  IonFooter,
  IonImg,
  IonPage,
} from "@ionic/react";

import HeaderComponent from "../../components/shared/header";

import skillsImage from "../../assets/images/skills-woman-w-bg-updated.webp";
import flowImage from "../../assets/images/flow-man-w-bg-updated.webp";
import "./Home.page.scss";
import { getCustomStyle } from "../../utils/styles";
import { products } from "./constants";
import ProductComponent from "./components/ProductComponent";
import PuzzleImage from "../../assets/images/pluralsight-puzzle-piece.webp";
import FooterComponent from "../../components/shared/footer";

const HomePage: React.FC = () => {
  const headlineSpanRef = useRef<HTMLSpanElement>(null);
  const ionContentRef = useRef<HTMLIonContentElement>(null);

  useEffect(() => {
    let activeHeadingLine = 0;
    const headingLines = [
      '<span class="headline-line--item slide slide--in">&nbsp;upskill your team</span>',
      '<span class="headline-line--item slide slide--in">&nbsp;collaborate better</span>',
      '<span class="headline-line--item slide slide--in">&nbsp;deliver faster</span>',
    ];
    const numOfChildren = headingLines.length;
    setInterval(() => {
      headingLines.forEach((headingLine, index) => {
        if (activeHeadingLine === index && headlineSpanRef.current) {
          headlineSpanRef.current.innerHTML = headingLines[activeHeadingLine];
        }
      });
      activeHeadingLine =
        activeHeadingLine >= numOfChildren - 1 ? 0 : activeHeadingLine + 1;
    }, 3000);
    const styleScrollbar = () => {
      let style = document.createElement("style");
      style.innerHTML = getCustomStyle();
      ionContentRef.current?.shadowRoot?.appendChild(style);
    };
    styleScrollbar();
  });
  return (
    <IonPage>
      <HeaderComponent />
      <IonContent ref={ionContentRef}>
        <div className="about">
          <IonCard className="home-card">
            <section>
              <h1 className="ion-text-center" id="heading-text">
                Vehlearn empowers you to
                <span ref={headlineSpanRef} id="heading-line">
                  <span className="headline-line--item slide slide--in">
                    &nbsp;upskill your team
                  </span>
                </span>
              </h1>
              <div className="ion-margin m-4">
                <p className="ion-text-center ion-no-margin">
                  Develop critical tech skills. Cut cycle times. Build happier,
                  healthier
                </p>
                <p className="ion-text-center ion-no-padding">
                  tech teams. And transform your goals into gains. All with
                  Vehlearn
                </p>
              </div>
              <div className="ion-text-center ion-margin-top ion-margin-bottom">
                <IonButton
                  className="un-capitalize"
                  color="danger"
                  size="default"
                  shape="round"
                >
                  View plans
                </IonButton>
              </div>
              <div className="skills-image-wrapper mt-5">
                <IonImg
                  className="skills-image"
                  src={skillsImage}
                  alt="Skills"
                />
                <IonImg className="skills-image" src={flowImage} alt="Skills" />
              </div>
            </section>
          </IonCard>
        </div>

        <section className="products-container">
          <h2 className="products-title">Products</h2>
          {products.map((product) => (
            <ProductComponent key={product.title} product={product} />
          ))}
        </section>
        <section className="ion-padding-start ion-padding-end why-us-container">
          <h2>Why VEhLearn?</h2>
          <p>
            Whether you’re an individual looking to learn Python to advance your
            career or an enterprise team looking to cut cycle times, speed up
            onboarding, or give your teams the skills to realize your
            strategies, we remove the challenges and roadblocks slowing you
            down. We’re advancing the world’s tech workforce, and that starts
            with making your work more efficient and effective—and giving you
            more to celebrate
          </p>
          <IonButton color="danger" shape="round">
            See our solutions
          </IonButton>
          <IonImg src={PuzzleImage} />
        </section>
        <FooterComponent />
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
