import {
  IonButton,
  IonCheckbox,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import "./Footer.component.scss";

const FooterComponent: React.FC = () => {
  return (
    <section className="footer ion-padding">
      <section className="newsletter">
        <h4 className="newsletter--heading">Newsletter</h4>
        <p className="newsletter--description">
          Sign up with your email to join our mailing list
        </p>
        <IonItem
          mode="ios"
          color="transparent"
          lines="none"
          className="newsletter--email"
        >
          <IonLabel position="stacked">
            <span color="danger">*</span>Email Address
          </IonLabel>
          <IonInput type="text" />
        </IonItem>
        <IonItem color="transparent" lines="none" className="newsletter--agree">
          <IonLabel slot="end" className="ion-no-margin ion-padding-start">
            I would like to receive emails from Pluralsight
          </IonLabel>
          <IonCheckbox className="ion-no-margin" mode="md" slot="start" />
        </IonItem>
        <IonButton shape="round" color="danger">
          Submit
        </IonButton>
      </section>
    </section>
  );
};

export default FooterComponent;
