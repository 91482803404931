import React from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonImg,
  IonItem,
  IonList,
  IonText,
} from "@ionic/react";
import { IProduct } from "../constants";
import "./ProductComponent.scss";

const ProductComponent: React.FC<{ product: IProduct }> = ({ product }) => {
  return (
    <IonCard className="product">
      <IonCardHeader>
        <IonImg src={product.imageUrl} />
      </IonCardHeader>
      <IonCardContent>
        <section>
          <h1 className="product--title">{product.title}</h1>
          <p className="product--description">{product.description}</p>
          <IonList className="product--sections">
            {product.subsections.map((subsection) => (
              <IonItem href={subsection.url} detail={true}>
                <IonText>{subsection.title}</IonText>
              </IonItem>
            ))}
          </IonList>
        </section>
        <IonButton
          className="ion-margin-top"
          href={product.url}
          shape="round"
          color="danger"
        >
          Explore {product.title}
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default ProductComponent;
