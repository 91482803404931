// @ts-ignore
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CourseObjectType } from "../../../../types/course";
import { ReduxStatus } from "../../../../utils/constants";
import { getCourses } from "./thunks";

type skillsStateType = {
  courses?: CourseObjectType[];
  status: string;
  error: string;
};

const initialState: skillsStateType = {
  courses: [],
  status: ReduxStatus.empty,
  error: "",
};

export const skillsSlice = createSlice({
  name: "skills",
  initialState,
  reducers: {
    reset: (state: any, action: PayloadAction<[]>) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCourses.pending,
      (state: skillsStateType, action: any) => {
        state.status = ReduxStatus.loading;
      }
    );

    builder.addCase(
      getCourses.fulfilled,
      (state: skillsStateType, action: any) => {
        state.status = ReduxStatus.loaded;
        state.courses = action?.payload?.results;
      }
    );

    builder.addCase(
      getCourses.rejected,
      (state: skillsStateType, action: any) => {
        state.status = ReduxStatus.loadFailed;
        state.error = action.payload;
      }
    );
  },
});

export const courseDetailsActions = skillsSlice.actions;

export default skillsSlice.reducer;
