import React, { useRef } from "react";
import DashboardHeader from "../../components/dashboard/header";

import "./Dashboard.page.scss";
import { IonContent } from "@ionic/react";
import FreeLibraryComponent from "../../components/skills/freelibrary";
import EnrollmentsComponent from "../../components/dashboard/enrollments";
import { useSelector } from "react-redux";

const Dashboard: React.FC = () => {
  const ionContentRef = useRef<HTMLIonContentElement>(null);

  const { user } = useSelector((state: any) => state.dashboard);

  return (
    <>
      <DashboardHeader />
      <IonContent ref={ionContentRef} className="dashboard-content">
        {user?.data && (
          <div className="dashboard-card">
            <EnrollmentsComponent />
            <FreeLibraryComponent />
          </div>
        )}
      </IonContent>
    </>
  );
};

export default Dashboard;
