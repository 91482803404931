import { IonButton } from "@ionic/react";
import { getLoginUrl } from "../../../utils/auth";

type LoginButtonProps = {
  gotoUrl?: string;
};

const LoginButtonComponent = (props: LoginButtonProps) => {
  const { gotoUrl } = props;
  return (
    <IonButton href={getLoginUrl(gotoUrl)} className="un-capitalize">
      Sign In
    </IonButton>
  );
};

export default LoginButtonComponent;
