// @ts-ignore
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CourseObjectType } from "../../../types/course";
import { ReduxStatus } from "../../../utils/constants";
import { getCourseDetails } from "./thunks";

type CourseDetailsStateType = {
  course?: CourseObjectType | null;
  status: string;
  error: string;
};

const initialState: CourseDetailsStateType = {
  course: null,
  status: ReduxStatus.empty,
  error: "",
};

export const courseDetailsSlice = createSlice({
  name: "courseDetails",
  initialState,
  reducers: {
    reset: (state: any, action: PayloadAction<[]>) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCourseDetails.pending,
      (state: CourseDetailsStateType, action: any) => {
        state.status = ReduxStatus.loading;
      }
    );

    builder.addCase(
      getCourseDetails.fulfilled,
      (state: CourseDetailsStateType, action: any) => {
        state.status = ReduxStatus.loaded;
        state.course = action.payload;
      }
    );

    builder.addCase(
      getCourseDetails.rejected,
      (state: CourseDetailsStateType, action: any) => {
        state.status = ReduxStatus.loadFailed;
        state.error = action.payload;
      }
    );
  },
});

export const courseDetailsActions = courseDetailsSlice.actions;

export default courseDetailsSlice.reducer;
