import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { caretDownCircle } from "ionicons/icons";

import "./Contents.component.scss";

type CourseOutlineRecordType = {
  display_name: string;
  children: any[];
};

type ContentsPropTypes = {
  content: CourseOutlineRecordType[];
  color?: string;
  handleBlockClick?: any;
};

const ContentsComponent = (props: ContentsPropTypes) => {
  const { content, color, handleBlockClick } = props;

  return (
    <div className="container">
      <IonAccordionGroup>
        {content?.map((item, i) => (
          <IonAccordion
            key={`${i}-course-content`}
            toggleIcon={caretDownCircle}
            toggleIconSlot="start"
          >
            <IonItem slot="header" color={color}>
              <IonLabel>{item.display_name}</IonLabel>
            </IonItem>
            {item?.children && (
              <IonAccordionGroup slot="content">
                {item?.children.map((section) => (
                  <IonAccordion
                    toggleIcon={caretDownCircle}
                    toggleIconSlot="end"
                    key={`${section.id}-section`}
                  >
                    <IonItem color={color} slot="header">
                      <IonLabel className="ion-padding-start">
                        {section.display_name}
                      </IonLabel>
                    </IonItem>

                    {section?.children && (
                      <div slot="content">
                        {section?.children.map((subSection: any) => (
                          <IonItem
                            button={!!handleBlockClick}
                            key={`${subSection.id}-sub-section`}
                            color={color}
                            onClick={() => {
                              handleBlockClick(subSection.student_view_url);
                            }}
                            slot="header"
                          >
                            <IonLabel className="ion-padding-start">
                              {subSection.display_name}
                            </IonLabel>
                          </IonItem>
                        ))}
                      </div>
                    )}
                  </IonAccordion>
                ))}
              </IonAccordionGroup>
            )}
          </IonAccordion>
        ))}
      </IonAccordionGroup>
    </div>
  );
};

export default ContentsComponent;
