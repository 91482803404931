export function getCustomStyle() {
  return `
    main::-webkit-scrollbar {
      width: 6px;
      position: absolute;
      top: 0;
      right: 0;
    }
    main::-webkit-scrollbar-track {
      background: transparent;
    }
    main::-webkit-scrollbar-track:hover {
      background: transparent;
    }
    main::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 8px;
    }
    main::-webkit-scrollbar-thumb:hover {
      background: #ccc
    }
    main::-webkit-scrollbar-thumb:active {
      background: var(--ion-color-danger);
    }
    `;
}

export function getBackgroundStyle() {
  return `
    main {
      background: #0d0d0d;
    }
  `;
}

export const styleScrollbar = (ref: any) => {
  let style = document.createElement("style");
  style.innerHTML = getCustomStyle();
  ref.current?.shadowRoot?.appendChild(style);
};
