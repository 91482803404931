import { useSelector } from "react-redux";
import { CourseObjectType } from "../../../types/course";
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import CourseComponent from "../../shared/course";
import React, { useEffect } from "react";
import { BASE_API_URL, ReduxStatus } from "../../../utils/constants";
import { store } from "../../../app/store";
import { getUserEnrollments } from "../../../pages/dashboard/redux/thunks";
import Spinner from "../../shared/loaders/spinner";

const EnrollmentsComponent = () => {
  const { enrollments } = useSelector((state: any) => state.dashboard);

  const courses = enrollments?.data?.courses;

  const coursesToDisplay: any =
    courses?.length > 3 ? courses?.slice(0, 3) : courses;

  useEffect(() => {
    if (enrollments.status === ReduxStatus.empty) {
      store.dispatch(getUserEnrollments({}));
    }
  }, []);

  return (
    <div className="mb-5">
      <IonText>
        <h3>Your courses</h3>
        <span>
          Courses you are enrolled in{" "}
          <span>
            <a href="#!">See all</a>
          </span>
        </span>
        <div className="mt-4">
          <IonGrid>
            <IonRow>
              {coursesToDisplay?.map((item: any) => {
                const courseItem: CourseObjectType = {
                  name: item.display_name_with_default,
                  id: item.course_id,
                  course_id: item.course_id,
                  pacing: "",
                  media: {
                    image: {
                      raw: `${BASE_API_URL}${item.course_image_url}`,
                    },
                  },
                };
                return (
                  <IonCol key={`in-progress-course-${item.course_id}`}>
                    <CourseComponent course={courseItem} />
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
        </div>
      </IonText>
      {enrollments.status === ReduxStatus.loading && <Spinner />}
    </div>
  );
};

export default EnrollmentsComponent;
