import SkillsImage from "../../assets/images/skills-badges-solid.webp";
import UniversityImage from "../../assets/images/flow-dashboard-solid-2.webp";

export interface IProduct {
  imageUrl: string;
  title: string;
  iconUrl: string;
  description: string;
  subsections: { title: string; url: string }[];
  url: string;
}

export const products: IProduct[] = [
  {
    imageUrl: SkillsImage,
    title: "Skills",
    iconUrl: "",
    description:
      "Empower yourself or your team to develop the skills critical to delivering on your" +
      " career goals and initiatives with our tech skills platform.",
    subsections: [
      {
        title: "View Courses",
        url: "/",
      },
      {
        title: "For Individuals",
        url: "/",
      },
      {
        title: "Take a skilled assessment",
        url: "/",
      },
      {
        title: "View Plans",
        url: "/",
      },
    ],
    url: "/",
  },
  {
    imageUrl: UniversityImage,
    title: "Universities",
    iconUrl: "",
    description:
      "Ultimately, online learning not only increases the reach and impact of universities but also" +
      " allows them to adapt to the evolving needs of a rapidly changing world.",
    subsections: [
      {
        title: "View Universities",
        url: "/",
      },
      {
        title: "For Individuals",
        url: "/",
      },
    ],
    url: "/",
  },
];
