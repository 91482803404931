import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";

import "./Learning.page.scss";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import ContentsComponent from "../../components/course-details/table-of-contents/Contents.component";
import DashboardHeader from "../../components/dashboard/header";
import { useSelector } from "react-redux";
import { ReduxStatus } from "../../utils/constants";
import { store } from "../../app/store";
import { getCourseOutline } from "./redux/thunks";
import Spinner from "../../components/shared/loaders/spinner";

interface MatchParams {
  courseId: string;
  blockId: string;
}

const iframeParams: string =
  "?show_title=0&show_bookmark_button=0&recheck_access=1&view=student_view";

const LearningPage: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { outline, status } = useSelector((state: any) => state.learning);
  const { user } = useSelector((state: any) => state.dashboard);
  const [currentBlockUrl, setCurrentBlockUrl] = useState("");
  const courseId: string = match?.params?.courseId;

  useEffect(() => {
    if (status === ReduxStatus.empty) {
      store.dispatch(getCourseOutline(courseId));
    }
  }, [status, courseId]);

  useEffect(() => {
    const blockId: string = match?.params?.blockId;
    console.log(blockId, "the block id");
    if (blockId) {
      setCurrentBlockUrl("xblock/" + blockId + iframeParams);
    }
  }, []);

  const handleBlockSelected = (url: string) => {
    console.log(url, "the url");
    setCurrentBlockUrl(url + iframeParams);
  };

  return (
    <>
      <DashboardHeader />

      <IonGrid>
        <IonRow className="ion-margin-start">
          {outline && (
            <IonCol size="3">
              <div className="ion-margin">
                <h5>{outline.display_name}</h5>
                <hr />
                <h6>Course outline</h6>
              </div>

              <ContentsComponent
                color="dark"
                handleBlockClick={handleBlockSelected}
                content={outline.children}
              />
            </IonCol>
          )}
          <IonCol>
            {currentBlockUrl && (
              <iframe src={currentBlockUrl} className="learning-frame"></iframe>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>

      {status === ReduxStatus.loading && <Spinner />}
    </>
  );
};

export default LearningPage;
