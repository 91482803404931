export const ReduxStatus = {
  empty: "",
  loading: "loading",
  loaded: "loaded",
  loadFailed: "loadFailed",
  saving: "saving",
  saved: "saved",
  saveFailed: "saveFailed",
  editing: "editing",
  edited: "edited",
  editFailed: "editFailed",
  deleting: "deleting",
  deleted: "deleted",
};

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const HTTP_ORIGIN = process.env.REACT_APP_HTTP_ORIGIN;
