import http from "../../../utils/http";

const COURSE_OUTLINE_ENDPOINT = "/api/vl/completion/course_outline/";

const getCourseOutline = async (courseId: string) => {
  return await http.get(COURSE_OUTLINE_ENDPOINT + courseId);
};

const learningService = {
  getCourseOutline,
};

export default learningService;
