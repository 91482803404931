import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ReduxStatus } from "../../../utils/constants";
import { getUserDetails, getUserEnrollments } from "./thunks";
import { DashboardStateType } from "./types";

const initialState: DashboardStateType = {
  courses: [],
  programs: [],
  enrollments: {
    status: ReduxStatus.empty,
    error: null,
    data: null,
  },
  recommendedCourses: [],
  user: {
    status: ReduxStatus.empty,
    error: null,
    data: null,
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    reset: (state: any, action: PayloadAction<[]>) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getUserDetails.pending,
      (state: DashboardStateType, action: any) => {
        state.user.status = ReduxStatus.loading;
      }
    );

    builder.addCase(
      getUserDetails.fulfilled,
      (state: DashboardStateType, action: any) => {
        state.user.status = ReduxStatus.loaded;
        state.user.data = action?.payload;
      }
    );

    builder.addCase(
      getUserDetails.rejected,
      (state: DashboardStateType, action: any) => {
        state.user.status = ReduxStatus.loadFailed;
        state.user.error = action.payload;
      }
    );

    builder.addCase(
      getUserEnrollments.pending,
      (state: DashboardStateType, action: any) => {
        state.enrollments.status = ReduxStatus.loading;
      }
    );

    builder.addCase(
      getUserEnrollments.fulfilled,
      (state: DashboardStateType, action: any) => {
        state.enrollments.status = ReduxStatus.loaded;
        state.enrollments.data = action?.payload;
      }
    );

    builder.addCase(
      getUserEnrollments.rejected,
      (state: DashboardStateType, action: any) => {
        state.enrollments.status = ReduxStatus.loadFailed;
        state.enrollments.error = action.payload;
      }
    );
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
