import http from "../../../utils/http";

const USER_ENROLLMENTS_ENDPOINT = "/api/vl/dashboard/enrollments/";
const USER_DETAILS_ENDPOINT = "/api/user/v1/accounts";

const getUserEnrollments = async () => {
  return await http.get(USER_ENROLLMENTS_ENDPOINT);
};

const getUserDetails = async () => {
  return await http.get(USER_DETAILS_ENDPOINT);
};

const dashboardService = {
  getUserDetails,
  getUserEnrollments,
};

export default dashboardService;
