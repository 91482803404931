import { createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "./service";

export const getUserDetails = createAsyncThunk(
  "dashboard/getUserDetails",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getUserDetails();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getUserEnrollments = createAsyncThunk(
  "dashboard/getUserEnrollments",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getUserEnrollments();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
