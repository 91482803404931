import { createAsyncThunk } from "@reduxjs/toolkit";

import skillsService from "./service";

export const getCourses = createAsyncThunk(
  "courseDetails/getCourseDetails",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await skillsService.getCourses(params);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
