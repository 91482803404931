import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ReduxStatus } from "../../../utils/constants";
import { getCourseOutline } from "./thunks";

type learningStateType = {
  outline?: any;
  status: string;
  error: string;
};

const initialState: learningStateType = {
  outline: null,
  status: ReduxStatus.empty,
  error: "",
};

export const learningSlice = createSlice({
  name: "learning",
  initialState,
  reducers: {
    reset: (state: any, action: PayloadAction<[]>) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCourseOutline.pending,
      (state: learningStateType, action: any) => {
        state.status = ReduxStatus.loading;
      }
    );

    builder.addCase(
      getCourseOutline.fulfilled,
      (state: learningStateType, action: any) => {
        state.status = ReduxStatus.loaded;
        state.outline = action?.payload;
      }
    );

    builder.addCase(
      getCourseOutline.rejected,
      (state: learningStateType, action: any) => {
        state.status = ReduxStatus.loadFailed;
        state.error = action.payload;
      }
    );
  },
});

export const learningActions = learningSlice.actions;

export default learningSlice.reducer;
