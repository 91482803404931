import { Route, Redirect } from "react-router-dom";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { IonRouterOutlet } from "@ionic/react";

import TableOfContentsComponent from "../../components/course-details/table-of-contents";
import OverviewComponent from "../../components/course-details/overview";
import { useSelector } from "react-redux";
import { ReduxStatus } from "../../utils/constants";
import { store } from "../../app/store";
import { getCourseDetails } from "./redux/thunks";

interface MatchParams {
  courseId: string;
  courseSlug?: string;
}

const CourseDetailsPage: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { status } = useSelector((state: any) => state.courseDetails);
  const courseId: string = match?.params?.courseId;

  useEffect(() => {
    if (status === ReduxStatus.empty) {
      store.dispatch(getCourseDetails(courseId));
    }
  });
  return (
    <>
      <IonRouterOutlet>
        <Redirect from={`${match.url}`} exact to={`${match.url}/overview`} />
        <Route
          exact
          path={`/courses/:courseSlug/:courseId/table-of-contents`}
          component={TableOfContentsComponent}
        />
        <Route
          exact
          path={`/courses/:courseSlug/:courseId/overview`}
          component={OverviewComponent}
        />
      </IonRouterOutlet>
    </>
  );
};

export default CourseDetailsPage;
