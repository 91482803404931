import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import React, { lazy } from "react";
import { IonPage, IonRouterOutlet } from "@ionic/react";
import { RouteComponentProps } from "react-router";

const SkillPage = lazy(() => import("./skills/Skill.page"));

const ProductsRoute: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <IonRouterOutlet>
      <Route exact path={`${match.url}/skills`} component={SkillPage} />
    </IonRouterOutlet>
  );
};

export default ProductsRoute;
