import http from "../../../../utils/http";

const COURSES_ENDPOINT = "/api/courses/v1/courses/";

const getCourses = async (params: any) => {
  return await http.get(COURSES_ENDPOINT, { params });
};

const skillsService = {
  getCourses,
};

export default skillsService;
