import http from "../../../utils/http";

const COURSES_ENDPOINT = "/api/courses/v1/courses";

const getCourseDetails = async (courseId: string) => {
  return await http.get(`${COURSES_ENDPOINT}/${courseId}`);
};

const courseDetailsService = {
  getCourseDetails,
};

export default courseDetailsService;
