import { createAsyncThunk } from "@reduxjs/toolkit";

import learningService from "./service";

export const getCourseOutline = createAsyncThunk(
  "learning/getCourseOutline",
  async (courseId: string, { rejectWithValue }) => {
    try {
      const response = await learningService.getCourseOutline(courseId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
