import { Route } from "react-router-dom";
import { IonPage, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "../theme/variables.css";
import "../theme/global.scss";
import "../theme/scss/main.scss";
import React, { lazy, Suspense } from "react";
import ProductsRoute from "../pages/products/Products.route";
import PageLoadComponent from "../components/shared/loaders/page-load/PageLoad.component";
import HomePage from "../pages/home";
import Dashboard from "../pages/dashboard";
import CourseDetailsPage from "../pages/course-details/CourseDetails.page";
import LearningPage from "../pages/learning/Learning.page";

setupIonicReact();

const App: React.FC = () => (
  <IonReactRouter>
    <IonRouterOutlet>
      <Suspense fallback={<PageLoadComponent />}>
        <Route exact path="/" component={HomePage} />
        <Route path="/products" component={ProductsRoute} />
        <Route path="/dashboard" component={Dashboard} />
        <Route
          path="/courses/:courseSlug/:courseId"
          component={CourseDetailsPage}
        />
        <Route path="/learning/:courseId/:blockId" component={LearningPage} />
        <Route path="/learning/:courseId" component={LearningPage} />
      </Suspense>
    </IonRouterOutlet>
  </IonReactRouter>
);

export default App;
