import { CourseObjectType } from "../../../types/course";
import { IonButton, IonButtons, IonIcon } from "@ionic/react";

import "./Backdrop.component.scss";
import { playCircle } from "ionicons/icons";

type CourseBackdropPropType = {
  course: CourseObjectType;
};

const BackdropComponent = (props: CourseBackdropPropType) => {
  const { course } = props;
  return (
    <div className="container">
      <div
        style={{ background: `url('${course?.media?.image?.raw}')` }}
        className="backdrop"
      >
        <div className="backdrop-inner p-5">
          <div className="display-4">{course.name}</div>
          <div className="mt-2">
            <i>
              {course.pacing?.charAt(0).toUpperCase() + course.pacing?.slice(1)}{" "}
              pace
            </i>
          </div>
          <div className="mt-4 ion-margin-end">{course.short_description}</div>

          <div className="mt-5">
            <IonButton
              size="large"
              color="danger"
              shape="round"
              className="un-capitalize"
              href={`/learning/${course.id}`}
            >
              <IonIcon slot="start" icon={playCircle} />
              Start course
            </IonButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BackdropComponent;
