import HeaderComponent from "../../shared/header";
import BackdropComponent from "../backdrop";
import CourseTabsComponent from "../course-tabs";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentsComponent from "./Contents.component";
import { RouteComponentProps } from "react-router";
import { IonContent, IonPage } from "@ionic/react";
import { styleScrollbar } from "../../../utils/styles";
import { ReduxStatus } from "../../../utils/constants";
import { store } from "../../../app/store";

import { getCourseDetails } from "../../../pages/course-details/redux/thunks";
import Spinner from "../../../components/shared/loaders/spinner";

interface MatchParams {
  courseId: string;
  courseSlug?: string;
}

const TableOfContentsComponent: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { course, status } = useSelector((state: any) => state.courseDetails);
  const ionContentRef = useRef<HTMLIonContentElement>(null);

  styleScrollbar(ionContentRef);

  return (
    <IonPage>
      <HeaderComponent color="dark" />
      {course && (
        <>
          <IonContent color="dark" ref={ionContentRef}>
            <BackdropComponent course={course} />
            <CourseTabsComponent course={course} />
          </IonContent>
        </>
      )}

      {status === ReduxStatus.loading && <Spinner />}
    </IonPage>
  );
};

export default TableOfContentsComponent;
