import { IonSpinner } from "@ionic/react";

import "./Spinner.component.scss";
const SpinnerComponent: React.FC = () => {
  return (
    <div className="spinner-container">
      <div className="spinner">
        <IonSpinner name="lines-sharp"></IonSpinner>
      </div>
    </div>
  );
};

export default SpinnerComponent;
